@import "utils/_variables";
.pai-nosso {
  .page-loading {
    height: 100vh;
    width: 100%;
    background-color: #ffffff;
    color: #5a5a5a;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .fas {
      font-size: 40px; }
    p.msg {
      font-size: 10px;
      font-weight: bold; } }
  .page-error {
    height: 100vh;
    width: 100%;
    background-color: $fluxio-blue;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .fa-exclamation-triangle {
      font-size: 40px;
      margin-bottom: 5px; }
    p {
      margin: 0;
      font-weight: bold; }
    a.msg {
      color: #fff; } } }
